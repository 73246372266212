import { useEffect, useState } from "react";
import { getSingleCountry } from "../dao/Countries";

/**
 * @param {string} countryName // Country to get data for.
 * @param {object} options // Param options.
 */
export default function useCountry(countryName, options = {}) {
  const [country, setCountry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getCountriesData(countryName, options);
  }, []);

  function getCountriesData(countryName, options) {
    getSingleCountry(countryName, options)
      .then((res) => {
        setCountry(res[0]);
      })
      .catch((err) => {
        setHasError(true);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { country, loading, hasError, error };
}
