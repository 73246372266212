export default function Heading({ title, description, imageUrl }) {

  return(
    <div className="heading">
      <div className="heading__text">
        <h1>{title}</h1>
        {description &&
          <p>{description}</p>
        }
      </div>
      <div className="heading__image">
        <img src={imageUrl} alt="Destinations heading" loading="lazy" />
      </div>
    </div>
  );
}
