import { Link } from "react-router-dom";
import Card from "./Card";
import OptionsIcon from "../icons/OptionsIcon";
import { useState } from "react";
import { deleteDestination } from "../../dao/Destinations";

export default function DestinationCard({ destination }) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <Card>
      <div className="card__destination">
        <div className="card__destination--image">
          <span className="card__destination--category">
            {destination.category}
          </span>
          <span className="card__destination--options">
            <span
              className="options-toggle"
              onClick={() => setShowOptions(!showOptions)}
            >
              <OptionsIcon fill="#ffffff" />
            </span>
            {showOptions && (
              <div className="options">
                <button
                  className="options__button delete"
                  onClick={() => deleteDestination(destination.id)}
                >
                  Delete
                </button>
              </div>
            )}
          </span>
          <img src={destination.headerImage} loading="lazy" />
        </div>
        <h5>{destination.title}</h5>
        <p className="card__destination--description">
          {destination.description}
        </p>
        <Link className="card__destination--link" to={`/destination/${destination.id}`}>
          Read more
        </Link>
      </div>
    </Card>
  );
}
