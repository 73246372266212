import { useEffect, useState } from "react";
import { getAllCountries } from "../dao/Countries";

/**
 * @param {object} options // Param options.
 */
export default function useCountries(options = {}) {
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getCountriesData(options);
  }, []);

  function getCountriesData(options) {
    getAllCountries(options)
      .then((res) => {
        setCountries(res);
      })
      .catch((err) => {
        setHasError(true);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { countries, loading, hasError, error };
}
