import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../firebase";

const collectionName = "destinations";

/**
 * Create a destination.
 *
 * @param {object} data // The destination data.
 */
export async function createDestination(data) {
  await addDoc(collection(db, collectionName), data);
}

/**
 * Get destinations.
 *
 * @param {string} userID // The user ID.
 * @param {number} destinationCount // The amount of destinations.
 * @param {string} lastVisible // The last visible destination.
 */
export async function getDestinations(
  userID,
  destinationCount,
  lastVisible = null
) {
  const queryConstrains = [
    collection(db, collectionName),
    where("userID", "==", userID),
    orderBy("createdAt", "desc"),
    limit(destinationCount),
  ];

  if (lastVisible) {
    queryConstrains.push(startAfter(lastVisible));
  }

  return await getDocs(query(...queryConstrains))
    .then((res) => {
      const data = [];
      res.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      return data;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Get destination by ID.
 *
 * @param {string} destinationId // The destination ID.
 */
export async function getSingleDestination(destinationId) {
  return await getDoc(doc(db, collectionName, destinationId))
    .then((res) => {
      return {
        ...res.data(),
        id: res.id,
      };
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Get destinations by country.
 *
 * @param {string} userID // The user ID.
 * @param {number} destinationCount // The amount of destinations.
 * @param {string} country // The name of the country.
 * @param {string} lastVisible // The last visible destination.
 */
export async function getCountryDestinations(
  userID,
  destinationCount,
  country,
  lastVisible = null
) {
  const queryConstrains = [
    collection(db, collectionName),
    where("userID", "==", userID),
    where("country", "==", country),
    orderBy("createdAt", "desc"),
    limit(destinationCount),
  ];

  if (lastVisible) {
    queryConstrains.push(startAfter(lastVisible));
  }

  return await getDocs(query(...queryConstrains))
    .then((res) => {
      const data = [];
      res.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      return data;
    })
    .catch((err) => {
      return err;
    });
}

/**
 * Delete destination.
 *
 * @param {string} id // ID of the destination to delete.
 */
export async function deleteDestination(id) {
  await deleteDoc(doc(db, collectionName, id))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}
