import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Layout from "./components/general/Layout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Destinations from "./pages/Destinations";
import Countries from "./pages/Countries";
import SingleCountry from "./pages/SingleCountry";
import SingleDestination from "./pages/SingleDestination";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/destinations",
      element: <Destinations />,
    },
    {
      path: "/destination/:destinationId",
      element: <SingleDestination />,
    },
    {
      path: "/countries",
      element: <Countries />,
    },
    {
      path: "/countries/:countryName",
      element: <SingleCountry />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
  ]);

  return (
    <div className="App">
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </div>
  );
}
