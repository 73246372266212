import { useState } from "react";
import DestinationCard from "../components/cards/DestinationCard";
import useDestinations from "../hooks/useDestinations";
import useUser from "../hooks/useUser";
import DestinationModal from "../components/modals/DestinationModal";
import Heading from "../components/elements/Heading";

export default function Destinations() {
  const { user } = useUser();
  const [showModal, setShowModal] = useState(false);
  const { destinations, hasMorePages, loadMore } = useDestinations(
    user?.uid,
    9
  );

  return (
    <>
      {showModal && (
        <DestinationModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <div className="destinations">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Heading
                title="Destinations"
                imageUrl="https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
              />
            </div>
          </div>
          <div className="row destinations-feed">
            {destinations.length > 0 &&
              destinations.map((destination) => (
                <div className="col-12 col-lg-4" key={destination.id}>
                  <DestinationCard destination={destination} />
                </div>
              ))}
          </div>
          {hasMorePages && (
            <div className="destinations__load-more">
              <button onClick={loadMore} className="button button-primary">
                Load more
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
