import { useParams } from "react-router-dom";
import Heading from "../components/elements/Heading";
import useDestination from "../hooks/useDestination";

export default function SingleDestination() {
  const { destinationId } = useParams();
  const { destination } = useDestination(destinationId);

  return (
    <div className="single-country">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Heading
              title={destination?.title}
              imageUrl={destination?.headerImage}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h2>Description</h2>
            <p>{destination?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
