import { countriesApi } from "../constants";
import { getDataFromApi } from "./Api";

/**
 * Get all countries.
 *
 * @param {object} options // Param options.
 */
export async function getAllCountries(options) {
  return getDataFromApi(`${countriesApi}/all?${new URLSearchParams(options)}`)
    .then((res) => {
      const sortedData = res.sort((a, b) => {
        const aName = a.name.common;
        const bName = b.name.common;

        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });

      return sortedData;
    })
    .catch((err) => {
      console.error("Error getting all countries: ", err);
      return err;
    });
}

/**
 * Get single country.
 *
 * @param {string} countryName // Country to get data for.
 * @param {object} options // Param options.
 */
export async function getSingleCountry(countryName, options) {
  return getDataFromApi(`${countriesApi}/name/${countryName}?${new URLSearchParams(options)}`)
    .then((res) => {
      const sortedData = res.sort((a, b) => {
        const aName = a.name.common;
        const bName = b.name.common;

        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });

      return sortedData;
    })
    .catch((err) => {
      console.error("Error getting single country: ", err);
      return err;
    });
}
