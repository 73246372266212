import { useEffect, useState } from "react";
import { getDestinations, getCountryDestinations } from "../dao/Destinations";

export default function useDestinations(
  userID,
  destinationCount = 3,
  country = null,
) {
  const [destinations, setDestinations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMorePages, setHasMorePages] = useState(true);

  useEffect(() => {
    if (userID && destinationCount) {
      setDestinations([]);
      setLastVisible(null);

      if (country) {
        getCountryDestinationData(userID, destinationCount, country);
      } else {
        getDestinationData(userID, destinationCount);
      }
    }
  }, [userID, destinationCount, country]);

  function getDestinationData(userID, destinationCount, startAfter = null) {
    setLoading(true);

    getDestinations(userID, destinationCount, startAfter)
      .then((res) => {
        if (res.length < destinationCount) {
          setHasMorePages(false);
        }

        if (startAfter) {
          setDestinations((prevDestinations) => [...prevDestinations, ...res]);
        } else {
          setDestinations(res);
        }
        setLastVisible(res[res.length - 1]?.createdAt);
      })
      .catch((err) => {
        console.error("Error while fetching destinations: ", err);
        setHasError(true);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getCountryDestinationData(
    userID,
    destinationCount,
    country,
    startAfter = null
  ) {
    setLoading(true);

    getCountryDestinations(userID, destinationCount, country, startAfter)
      .then((res) => {
        if (res.length < destinationCount) {
          setHasMorePages(false);
        }

        if (startAfter) {
          setDestinations((prevDestinations) => [...prevDestinations, ...res]);
        } else {
          setDestinations(res);
        }
        setLastVisible(res[res.length - 1]?.createdAt);
      })
      .catch((err) => {
        console.error("Error while fetching single destinations: ", err);
        setHasError(true);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadMore() {
    if (country) {
      getCountryDestinationData(userID, destinationCount, country, lastVisible);
    } else {
      getDestinationData(userID, destinationCount, lastVisible);
    }
  }

  function refreshData() {
    setLastVisible(null);
    setDestinations([]);

    if (country) {
      getCountryDestinationData(userID, destinationCount, country, null);
    } else {
      getDestinationData(userID, destinationCount, null);
    }
  }

  return {
    destinations,
    loading,
    hasError,
    error,
    hasMorePages,
    loadMore,
    refreshData,
  };
}
