/**
 * Get data from API
 */
export async function getDataFromApi(endpoint) {
  return await fetch(endpoint, {
    method: "GET",
  })
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.error('Something went wrong:', err.message);
      return err;
    });
}
