import { useEffect, useState } from "react";
import DestinationCard from "../components/cards/DestinationCard";
import useDestinations from "../hooks/useDestinations";
import useUser from "../hooks/useUser";
import DestinationModal from "../components/modals/DestinationModal";

export default function Homepage() {
  const {user} = useUser();
  const {destinations, refreshData} = useDestinations(user?.uid);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (user?.uid) {
      refreshData();
    }
  }, [showModal]);

  return (
    <>
      {showModal && (
        <DestinationModal showModal={showModal} setShowModal={setShowModal} />
      )}
      <div className="homepage">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="homepage__heading">
                <h2>Destinations</h2>
                {user && (
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="button button-primary"
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="row destinations-feed">
            {destinations.length > 0 &&
              destinations.map((destination) => (
                <div className="col-12 col-lg-4" key={destination.id}>
                  <DestinationCard destination={destination} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
