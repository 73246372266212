import Card from "../cards/Card";
import CrossIcon from "../icons/CrossIcon";

export default function Modal({
  showModal,
  setShowModal,
  title,
  className,
  children,
}) {
  if (!showModal) {
    return;
  }

  return (
    <div className={`overlay ${className ? className : ""}`}>
      <Card className="modal">
        <div className="modal__heading">
          <h4>{title}</h4>
          <div
            className="modal__heading--close"
            onClick={() => setShowModal(false)}
          >
            <CrossIcon />
          </div>
        </div>
        <div className="modal__content">{children}</div>
      </Card>
    </div>
  );
}
