import { useState } from "react";
import Modal from "../elements/Modal";
import { destinationCategories } from "../../constants";
import { createDestination } from "../../dao/Destinations";
import useUser from "../../hooks/useUser";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase";
import useCountries from "../../hooks/useCountries";
import Compressor from "compressorjs";

export default function DestinationModal({ showModal, setShowModal, currentCountry = "" }) {
  const { user } = useUser();
  const { countries } = useCountries({
    fields: "name",
  });
  const [name, setName] = useState("");
  const [country, setCountry] = useState(currentCountry);
  const [category, setCategory] = useState(destinationCategories[0]);
  const [description, setDescription] = useState("");
  const [headerImage, setHeaderImage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    await new Compressor(headerImage, {
      quality: 0.6,
      maxWidth: 1080,
      maxHeight: 1080,
      success(result) {
        const compressedImage = new File([result], headerImage.name, {
          type: "image/jpeg",
        });

        const storageRef = ref(
          storage,
          `/images/destinations/${user.uid}/${Date.now()}-${headerImage.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, compressedImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // const percent = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
            // update progress
            // setPercent(percent);
          },
          (err) => {
            console.log(err);
          },
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
              await createDestination({
                userID: user?.uid,
                title: name,
                description,
                category,
                country,
                headerImage: url,
                createdAt: Date.now(),
              }).then(() => {
                setShowModal(false);
              });
            });
          }
        );
      },
      error(err) {
        console.log(err.message);
      },
    });
  }

  return (
    <Modal
      title="Create destination"
      showModal={showModal}
      setShowModal={setShowModal}
      className="destination-modal"
    >
      <form onSubmit={handleSubmit}>
        <label>Destination name*</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Destination name"
          required
        />

        <label>Country*</label>
        <select
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          required
        >
          {countries.map((country) => (
            <option value={country.name.common} key={country.name.common}>
              {country.name.common}
            </option>
          ))}
        </select>

        <label>Category*</label>
        <select
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        >
          {destinationCategories.map((destinationCategory) => (
            <option value={destinationCategory} key={destinationCategory}>
              {destinationCategory}
            </option>
          ))}
        </select>

        {/* TODO: make it a wysiwyg */}
        <label>Description</label>
        <textarea
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />

        <label>Image*</label>
        <input
          type="file"
          onChange={(e) => setHeaderImage(e.target.files[0])}
          placeholder="Image"
          required
        />

        <input className="button button-primary" type="submit" value="Submit" />
      </form>
    </Modal>
  );
}
