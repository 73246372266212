import { logout } from "../../firebase";
import useUser from "../../hooks/useUser";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import NavbarBrand from 'react-bootstrap/NavbarBrand';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';

export default function Header() {
  const {user} = useUser();

  return (
    <Navbar className="header" expand="lg">
      <Container>
        <NavbarBrand href="/">Destinations</NavbarBrand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <NavbarCollapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink href="/countries">Countries</NavLink>
            <NavLink href="/destinations">Destinations</NavLink>
            {user ?
              <p className="nav-link logout-button" onClick={logout}>
                Logout
              </p>
            :
              <NavLink href="/login">Login</NavLink>
            }
          </Nav>
        </NavbarCollapse>
      </Container>
    </Navbar>
  );
}
