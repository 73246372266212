import CountryCard from "../components/cards/CountryCard";
import useCountries from "../hooks/useCountries";

export default function Countries() {
  const { countries } = useCountries({
    fields: "name,flags",
  });

  return (
    <div className="countries">
      <div className="container">
        <div className="row countries-feed">
          {countries.length > 0 &&
            countries.map((country) => (
              <div className="col-12 col-lg-4" key={country.name.common}>
                <CountryCard country={country} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
