import { useEffect, useState } from "react";
import { getSingleDestination } from "../dao/Destinations";

/**
 * @param {string} destinationId // Country to get data for.
 */
export default function useDestination(destinationId) {
  const [destination, setDestination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    getDestinationData(destinationId);
  }, []);

  function getDestinationData(destinationId) {
    getSingleDestination(destinationId)
      .then((res) => {
        setDestination(res);
      })
      .catch((err) => {
        setHasError(true);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { destination, loading, hasError, error };
}
