import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

/**
 * Get user data.
 */
export default function useUser() {
  const [user, loading] = useAuthState(auth);

  return {user, loading};
}
