export const apiKey = process.env.REACT_APP_API_KEY;
export const authDomain = process.env.REACT_APP_API_KEY;
export const projectId = process.env.REACT_APP_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
export const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
export const appId = process.env.REACT_APP_APP_ID;
export const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

export const countriesApi = "https://restcountries.com/v3.1"

export const destinationCategories = [
  "Activity",
  "Architecture",
  "Food",
  "History",
  "Hotel",
  "Museum",
  "Nature",
  "Shopping",
  "Other",
];
