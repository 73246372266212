import { Link } from "react-router-dom";
import Card from "./Card";

export default function CountryCard({ country }) {
  return (
    <Card>
      <div className="card__country">
        <div className="card__destination--image">
          <img src={country.flags.png} alt={country.flags.alt} loading="lazy" />
        </div>
        <h5>{country.name.common}</h5>
        <Link className="card__destination--link" to={`/countries/${country.name.common}`}>
          Read more
        </Link>
      </div>
    </Card>
  );
}
