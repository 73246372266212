import { useParams } from "react-router-dom";
import useCountry from "../hooks/useCountry";
import Heading from "../components/elements/Heading";
import useDestinations from "../hooks/useDestinations";
import useUser from "../hooks/useUser";
import DestinationCard from "../components/cards/DestinationCard";

export default function SingleCountry() {
  const { countryName } = useParams();
  const { user } = useUser();
  const { country } = useCountry(countryName, {
    fields: "name,flags",
  });
  const { destinations, hasMorePages, loadMore } = useDestinations(
    user?.uid,
    9,
    countryName,
  );

  return (
    <div className="single-country">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Heading title={countryName} imageUrl={country?.flags?.png} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="homepage__heading">
              <h2>Destinations</h2>
            </div>
          </div>
        </div>
        <div className="row destinations-feed">
          {destinations.length > 0 &&
            destinations.map((destination) => (
              <div className="col-12 col-lg-4" key={destination.id}>
                <DestinationCard destination={destination} />
              </div>
            ))}
        </div>
        {hasMorePages && (
          <div className="destinations__load-more">
            <button onClick={loadMore} className="button button-primary">
              Load more
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
